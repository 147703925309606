.login-wrapper {
  background: linear-gradient(180deg, #FF9668 0%, #FF5003 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  width: 25em; 
  margin-bottom: 10px;
}
