:root {
  --gray-color: #e1e1e1;
  --lightGray: #fafafa;
  --orange: #ff5e0a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray-color, rgb(223, 218, 218));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pagination {
  list-style-type: none;
  padding: 10px 1%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

li.active > a {
  background: var(--orange, rgb(232, 116, 15));
  color: var(--lightGray, lightgray);
}

li > a {
  cursor: pointer;
  padding: 10px;
  background: var(--lightGray, lightgray);
  padding: 10px;
  border-radius: 5px;
  user-select: none;
}
