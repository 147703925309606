.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: calc(100vw - 100%);
}

.searchOptionDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill-level-info {
  font-size: medium;
  text-align: center;
}

.search {
  width: 300px;
}

@media (min-width: 300px) and (max-width: 515px) {
  .search {
    width: 300px;
  }

  .searchOptionsRowDiv {
    display: flex;
    flex-direction: column-reverse;
    width: 300px;
  }

  .question {
    margin-top: 15px;
  }

  .totalResultsDiv {
    font-size: 0.95em;
  }
}

.notFoundDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 20px;
  margin-top: 50px;
}

.notFoundText {
  font-weight: bold;
}

.question {
  display: flex;
  flex-direction : column;
}

.searchOptionsRowDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  padding: 1%;
}

.modal {
  position: relative;
  padding: 14px;
  background: #fff;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 4px;
}

.totalResultsDiv {
  display: flex;
  justify-content: center;
}

.searchDiv {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.searchOptionDropdown {
  margin: 10px;
  max-width: 180px;
  max-height: 180px;
}

.box {
  min-width: 310px;
}
