.skill-card {
  width: 300px;
  margin-left: 2%;
  margin-right: 2%;
  place-self: center;
  margin-bottom: 9%;
}

@media screen and (min-width: 500px) and (max-width: 650px) {
  .skill-card {
    width: 95%;
  }
}

.separator {
  margin-bottom: 5% !important;
  margin-top: 5% !important;
}

.skill-title {
  height: 60px;
}

.skill-details {
  height: 200px;
  font-size: 14px;
  overflow-wrap: break-word;
  overflow-y: auto;
}

.english-details {
  height: 200px;
  font-size: 30px;
  overflow-wrap: break-word;
  overflow-y: auto;
}
