.add-skills-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

@media screen and (min-width: 300px) and (max-width: 650px) {
  .add-skills-container {
    display: grid;
    grid-template-columns: auto;
  }
}

.add-skills-text {
  color: rgb(0, 0, 0);
}

.add-skills-icon {
  fill: rgb(190, 190, 190);
}

.skills-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2%;
  width: 980px;
}

@media screen and (min-width: 500px) and (max-width: 980px) {
  .skills-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: calc(20px + 2%);
    margin-right: calc(20px + 2%);
    width: auto;
    justify-content: center;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .skills-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: calc(20px + 2%);
    margin-right: calc(20px + 2%);
    margin-top: 5%;
    width: auto;
    justify-content: center;
  }
}

.skills-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
