.modal-container {
  position: absolute;
  top: 25%;
  left: calc(50% - 200px);
  background-color: white;
  width: 400px;
  border-radius: 1%;
  padding: 2%;
}

.modal-label {
  margin-top: 5% !important;
  margin-bottom: 1% !important;
}

.modal-divider {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}

.modal-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-level-description {
  font-size: 13px;
}

.skill-level-description-container {
  margin-top: 1%;
}
